import clsx from 'clsx';
import React from 'react';
import { BaseFormControl, BaseSelectEvents } from '../Form.models';
import { FormElementContainer } from '../FormElementContainer';
import classes from './Select.scss';

export interface SelectProps extends BaseFormControl, BaseSelectEvents {
  /** Current value the form control has */
  value?: string | number | string[] | undefined;
  /** Array of options that can be selected from */
  options?: { value: string | number; label: string | number }[];
  /** Whether or not the control should start focused (default: false) */
  autoFocus?: boolean;
  /** Defines whether an empty option should be added as the first option (default: false) */
  addEmptyOption?: boolean;
  /** Select placeholder */
  placeholder?: string;
}

export const Select: React.FC<SelectProps> = ({
  name,
  id,
  value = undefined,
  options = [],
  disabled = false,
  error,
  autoFocus = false,
  addEmptyOption = false,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  className = '',
  ...rest
}) => {
  const errorMsg: string | undefined = error;

  return (
    <FormElementContainer
      {...rest}
      className={clsx(classes.container, 'select-container', className)}
      error={errorMsg}
      dataTestFieldType="Select"
    >
      <select
        className={clsx({ [classes.hasError]: errorMsg !== undefined })}
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        autoFocus={autoFocus}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        {addEmptyOption &&
          (placeholder === null || placeholder === undefined) && (
            <option value=""></option>
          )}
        {placeholder && (
          <option value="" disabled>
            {disabled ? '' : placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </FormElementContainer>
  );
};
