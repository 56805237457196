import React, { FormEvent } from 'react';
import { DynamicListDataEntryRenderer } from '../../../../DynamicDataList/DynamicDataList.model';
import { Select } from '../../../../FormElements';
import { CreateSelectRendererConfig } from '../renderers.model';
import classes from './createSelectRenderer.scss';

/**
 * Creates a simple component that can be used as an input field
 * @param Object configuration object of type CreateSelectRendererConfig
 * @example createSelectRenderer({
        options: [
          { value: 'title 1', label: 'Item 1' },
          { value: 'title 2', label: 'Item 2' }
        ],
        defaultValue: 'title 1',
      })
 */
export const createSelectRenderer = ({
  id,
  name = '',
  placeholder,
  options = [],
  defaultValue,
  transform = (value: string) => value,
}: CreateSelectRendererConfig = {}): DynamicListDataEntryRenderer => {
  const selectRenderer = (
    currentValue: unknown,
    error: string | undefined,
    onValueChange: (value: unknown) => void,
    disabled = false,
  ): React.ReactNode => {
    if (defaultValue && currentValue === undefined) {
      onValueChange(defaultValue);
    }

    const onChangeHandler = (e: FormEvent<HTMLSelectElement>): void => {
      onValueChange(transform(e.currentTarget.value)); // emit onChange with transformed value
    };

    return (
      <Select
        id={id}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChangeHandler}
        inlineMode={true}
        error={error}
        className={classes.container}
        value={(currentValue as string) ?? ''}
        options={options}
      />
    );
  };

  return selectRenderer;
};
